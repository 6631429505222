import React from "react";
import tw from "twin.macro";
import { graphql, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { PageLayout, CTA, BlogCard } from "../components";

const Blog = ({
  data: {
    allStrapiArticles: { edges },
  },
}) => {
  const blogposts = edges.map((a) => a.node);
  return (
    <PageLayout
      title="MKO Blog"
      subtitle="Catch up with the latest in eyecare and eyewear."
      mainStyles={tw`bg-denim-50`}
    >
      <section tw="py-8 text-gray-500 container mx-auto">
        <div tw="md:flex md:flex-wrap space-y-6 max-w-lg md:max-w-none mx-auto grid gap-5">
          {blogposts.map((post) => (
            <>
              <div tw="hidden md:block bg-white p-4 rounded-lg shadow-lg w-full">
                <div tw="flex flex-wrap space-y-4 md:space-y-0">
                  <div tw="w-full md:w-4/12">
                    <Link
                      to={`/blog/${post.slug}`}
                      tw="block h-full hover:opacity-75"
                    >
                      <GatsbyImage
                        image={getImage(post.image.localFile)}
                        tw="h-full object-cover rounded w-full"
                        alt={post.image.alternativeText}
                      />
                    </Link>
                  </div>
                  <div tw="flex flex-col justify-center px-4 w-full md:w-8/12">
                    <h6 tw="font-semibold leading-tight mb-2 text-denim-600">
                      {post.category.name}
                    </h6>
                    <Link
                      to={`/blog/${post.slug}`}
                      tw="block hover:text-denim-600 mb-2 text-gray-800"
                    >
                      <h5 tw="font-bold leading-tight text-xl">{post.title}</h5>
                    </Link>
                    <p tw="mb-4">{post.description}</p>
                    <div>
                      <Link
                        to={`/blog/${post.slug}`}
                        tw="font-semibold hover:text-gray-900 inline-block text-gray-600 text-sm"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div tw="md:hidden mx-4">
                <BlogCard {...post} />
              </div>
            </>
          ))}
        </div>
      </section>
      <CTA />
    </PageLayout>
  );
};

export default Blog;

export const query = graphql`
  query blogPageQuery {
    allStrapiArticles(
      sort: { fields: publishedAt, order: DESC }
      filter: { groups: { elemMatch: { name: { eq: "MKO" } } } }
    ) {
      edges {
        node {
          content
          author {
            picture {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 50)
                }
              }
            }
            name
          }
          category {
            name
          }
          description
          image {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          publishedAt
          title
          slug
        }
      }
    }
  }
`;
